import React from "react";
import { useWordpressCustomData } from "../services/WordpressFetchData";

import Nav from "../components/ui/Nav";
import AnimatedPage from "../components/AnimatedPage";
import Loading from "../components/animations/Loading.jsx";

import Marquee from "../components/ui/Marquee";
import Footer from "../components/ui/Footer";

import personnages from "../assets/img/illustrations/lhoist-personnages-a-propos.svg";

export default function About() {
	const pageDatas = useWordpressCustomData(`/page/145`);
	if (!pageDatas)
		return (
			<AnimatedPage className='page-container page-container--about  '>
				<div className='content-page '>
					<Nav />
					<Loading />
				</div>
			</AnimatedPage>
		);
	return (
		<AnimatedPage className='page-container page-container--about  '>
			<div className='content-page '>
				<Nav />

				<div className='editor_inner_content'>
					<div dangerouslySetInnerHTML={{ __html: pageDatas.post_content }} />
					<img className='personnages' src={personnages} alt='' />
					<Marquee />
				</div>
				<Footer />
			</div>
		</AnimatedPage>
	);
}
