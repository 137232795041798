import { Link } from "react-router-dom";
import AppLogoMarkup from "../components/ui/AppLogoMarkup";
import AnimatedPage from "../components/AnimatedPage";
import { useUser } from "../hooks/useUser";
import LanguageSelect from "../components/ui/LanguageSelect";

import truckAnimation from "../assets/animations/chargement-vehicules";

import Lottie from "react-lottie";
import Loading from "../components/animations/Loading";

export default function Welcome() {
	const { screensTranslations } = useUser();

	if (!screensTranslations || !screensTranslations.welcome) return <Loading />;
	const currentScreenTranslations = screensTranslations.welcome;

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: truckAnimation,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	return (
		<AnimatedPage className='page-container page-container--welcome'>
			<AppLogoMarkup />
			<div className='truck-animation'>
				<Lottie options={defaultOptions} />
			</div>
			<div className='interaction-buttons'>
				<LanguageSelect />
				<Link
					to='/warning'
					title={currentScreenTranslations.enter}
					className='cta cta--construction cta--lhoist cta--button-icon cta--start'>
					<span>{currentScreenTranslations.enter}</span>
				</Link>
			</div>
		</AnimatedPage>
	);
}
