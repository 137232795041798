import React from "react";
import Lottie from "react-lottie";
import loadingTruck from "../../assets/animations/chargement-vehicules-boom.json";

import { useUser } from "../../hooks/useUser";

export default function Loading() {
	const { screensTranslations } = useUser();

	if (!screensTranslations || !screensTranslations?.ui?.loading) return;

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: loadingTruck,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	return (
		<div className='loading'>
			<div className='loading__animation'>
				<Lottie options={defaultOptions} />
			</div>
			<p className='loading__text'>{screensTranslations.ui.loading}...</p>
		</div>
	);
}
